// Angular modules
import { Injectable } from '@angular/core';
import {
  DataListOperation,
  DataListHeader,
  DataListHeaderItem,
  DataList,
  DataListItem
} from '@models/index';

@Injectable()
export abstract class DataListConfigurerHelper<T> {

  public abstract getDefaulDataList(): DataList<DataListItem<T>>;

  public abstract getDefaulDataListHeader(): DataListHeader;

  public abstract getDefaultDataListHeaderItems(): DataListHeaderItem[];

  public abstract getDefaultDataListOperations(): DataListOperation[];

  public abstract getDefaultBulkOperations(): DataListOperation[];

}
